import React from 'react'
import {
  array,
  func,
  object,
  string
} from 'prop-types'
import {
  DialogContentText,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const ConfirmSelect = ({
  contentText,
  handleSelection,
  inputText,
  options,
  selectedOption,
}) => {

  return (
    <>
      <DialogContentText>
        {contentText}
      </DialogContentText>
      <Autocomplete
        getOptionLabel={({ name }) => name}
        loading={options.length === 0}
        onChange={handleSelection}
        options={options}
        renderInput={params => (
          <TextField {...params} label={inputText} variant="outlined" fullWidth />
        )}
        value={selectedOption}
      />
    </>
  )
}

ConfirmSelect.propTypes = {
  contentText: string,
  handleSelection: func,
  inputText: string,
  options: array,
  selectedOption: object,
}

ConfirmSelect.defaultProps = {
  options: [],
  selectedOption: { id: '', name: '' }
}

export default ConfirmSelect
