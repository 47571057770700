import API from './apiClient'
import { diffObject, buildImageFormData, downloadCsv } from './dataUtils'
const apiUrl = '/api/v1'

export default {

  getSimple: async ( resource ) => {
    const url  = `${apiUrl}/${resource}`

    try {
      const response = await API.GET(
        url,
        {}
      )
        return  {
          data: response.data.content,
        }
      } catch (err) {
        console.error(err)
      }
  },

  getList: async (resource, params) => {
    const { pagination, sort } = params
    const { page, perPage } = pagination
    const { field, order } = sort
    const skip = (page - 1) * perPage

    const url = `${apiUrl}/${resource}`

    try {
      const response = await API.GET(
        url,
        {},
        {
          ...params,
          ...(params.filter && { q: params.filter.q }),
          ...(resource === 'campuses' && { expands: 'campus_ministries.ministry',
          includes: 'campus_ministry_status_pending',
          fields: 'id,prayer_walks_count,walker_prayer_walks_count,completed_walker_prayer_walks_count,unit_id,sortable_name,address1,address2,zipcode,institution_size_text,institution_size_code,institution_size,zip_plus4,latitude,longitude,state,state_name,created_at,updated_at,ipeds_id,name,aliases,url,city,county,locale_type,graduate_offered,highest_degree_offered,highest_level_offered,undergrad_offered,everycampus_id,phone,fax,country,established_at,carnegie_classification,doe_affiliation_code,higest_level_offered,ope_id,stylized_name,shortname,unique_zip,delivery_point_code,fice_number,nursing_offered,theology_offered,is_satellite,enrollment,ic_level,is_medical_nursing_school,is_religious_school,is_christian_seminary,scope,campus_ministries,campus_ministries.ministry_id,campus_ministries.status,campus_ministries.id,campus_ministries.campus_id'
        }),
          ...(resource === 'campus_ministries' && { expands: 'ministry' }),
          limit: perPage,
          skip,
          order_by: order,
          sort_by: field,
        }
      )
      return  {
        data: response.data.content,
        total: response.data.meta.paging.count
      }
    } catch (err) {
      console.error(err)
    }
  },

  getOne : async (resource, params) => {
    const { id } = params
    const url = `${apiUrl}/${resource}/${id}`
    try {
      const response = await API.GET(
        url,
        {},
        params
      )

      return  {
        data: response.data.content
      }
    } catch (err) {
      console.error(err)
    }
  },


  getMany : async (resource, params) => {
    console.error('getMany is not supported by the EC API')
    return Promise.resolve()
  },

  getManyReference : async (resource, params) => {
    const { id } = params
    const { pagination, sort } = params
    const { page, perPage } = pagination
    const { field, order } = sort
    const skip = (page - 1) * perPage
    const url = `${apiUrl}/${resource}/${id}`
    try {
      const response = await API.GET(
        url,
        {},
        {
          ...params,
          limit: perPage,
          skip,
          order_by: order,
          sort_by: field,
        }
      )
      return  {
        data: response.data.content,
      }
    } catch (err) {
      console.error(err)
    }
  },


  update : async (resource, params) =>{
    const { id, data, previousData } = params
    const changedData = diffObject(previousData, data)
    const url = `${apiUrl}/${resource}/${id}`
    let response

    try {
      if (changedData.icon_url || changedData.brand_logo_url) {

        const formData = buildImageFormData(changedData)

        response = await API.UPLOAD(
          'PUT',
          url,
          {
            'Content-Type': 'multipart/form-data; charset=utf-8'
          },
          formData
        )
      } else {
        response = await API.PUT(
          url,
          {},
          changedData
        )
      }

      return {
        data: response.data.content
      }
    } catch (err) {
      console.error(err)
    }
  },


  updateMany : async (resource, params) => {
    const id = params.id
    const data = params.data
    const url = `${apiUrl}/${resource}/${id}`
    try {
      const response = await API.PUT(
        url,
        {},
        data,
        params
      )
      return  {
        data: response.data.content
      }
    } catch (err) {
      console.error(err)
    }
  },

  create : async (resource, params) => {
    const { data } = params
    const url = `${apiUrl}/${resource}`
    let response
    try {
      if (data.icon_url || data.brand_logo_url) {

        const formData = buildImageFormData(data)

        response = await API.POST(
          url,
          {
            'Content-Type': 'multipart/form-data; charset=utf-8'
          },
          formData
        )
      } else {
        response = await API.POST(
          url,
          {},
          data
        )
      }
      return  {
        data: response.data.content
      }
    } catch (err) {
      console.error(err)
    }
  },


  delete : async (resource, params) => {
    const { id } = params
    const url = `${apiUrl}/${resource}/${id}`

    try {
      const response = await API.DELETE(
        url,
        {},
        params
      )
      return  {
        data: response.data
      }
    } catch (err) {
      console.error(err)
    }
  },

  deleteMany : async (resource, params) => {
    const url = `${apiUrl}/${resource}`
    try {
      const response = await API.DELETE(
        url,
        {},
        params
      )
      return  {
        data: response.data.content
      }
    } catch (err) {
      console.error(err)
    }
  },

  downloadFile: async (resource, id) => {
    const url = `${apiUrl}/${resource}/${id}`
    try {
      const response = await API.GET(
        url,
        {}
      )
      downloadCsv(response.data)
      return {
        data: response.data
      }
    } catch (err) {
      console.error(err)
    }
  }

}

