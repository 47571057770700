import React from 'react'
import {
  TextInput,
  Filter
} from 'react-admin'

const CampusesFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

export default CampusesFilter
