import React from 'react'
import {
  bool,
  func,
  oneOfType,
  string
} from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import ActionCheck from '@material-ui/icons/CheckCircle'
import AlertError from '@material-ui/icons/ErrorOutline'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const Confirm = ({
  cancelText,
  confirmDisable,
  confirmText,
  handleCancel,
  handleClose,
  handleConfirm,
  isOpen,
  content,
  title
}) => {
  // constants
  const classes = useStyles()

  return (
    <Dialog
      fullWidth={true}
      open={isOpen}
      onClose={handleClose}
    >
      {title &&
        <DialogTitle>
          {title}
        </DialogTitle>
      }
      <DialogContent>
        {
          typeof content === 'function' ? content() :
            <DialogContentText>
              {content}
            </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.confirmWarning}
          onClick={handleCancel}
        >
          <AlertError className={`${classes.iconPaddingStyle} ${classes.confirmWarning}`} />
          {cancelText}
        </Button>
        <Button
          autoFocus
          className={classes.confirmPrimary}
          disabled={confirmDisable}
          onClick={handleConfirm}
        >
          <ActionCheck className={`${classes.iconPaddingStyle} ${classes.confirmPrimary}`} />
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(
  theme => ({
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    confirmWarning: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
  }),
  { name: 'RaConfirm' }
)

Confirm.propTypes = {
  cancelText: string,
  confirmDisable: bool,
  confirmText: string,
  content: oneOfType([func, string]),
  handleCancel: func,
  handleClose: func,
  handleConfirm: func,
  isOpen: bool,
  title: string
}

Confirm.defaultProps = {
  confirmDisable: false
}

export default Confirm
