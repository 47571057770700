import React from 'react'
import { Admin, Resource } from 'react-admin'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import DescriptionIcon from '@material-ui/icons/Description'
import FlareIcon from '@material-ui/icons/Flare'
import MinistriesCreate from '../Ministries/MinistriesCreate'
import MinistriesEdit from '../Ministries/MinistriesEdit'
import MinistriesList from '../Ministries/MinistriesList'
import CampusMinistriesEdit from '../CampusMinistries/CampusMinistriesEdit'
import CampusesCreate from '../Campuses/CampusesCreate'
import CampusesEdit from '../Campuses/CampusesEdit'
import CampusesList from '../Campuses/CampusesList'
import ReportsList from '../Reports/ReportsList'
import authProvider from '../../utils/authProvider'
import dataProvider from '../../utils/ecDataProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'

import Login from '../Login/Login'
import LogoutButton from '../LogoutButton/LogoutButton'
import ecaTheme from './ecaTheme.js'
import './App.scss'
const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en',{allowMissing: true})


const AppRoot = () => {

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      logoutButton={LogoutButton}
      theme={ecaTheme}
    >
      { permissions => [
          permissions && 
          <Resource
            name="campuses"
            list={CampusesList}
            edit={CampusesEdit}
            create={CampusesCreate}
            icon={LocationCityIcon}
          />,
          permissions && 
          <Resource
            name="ministries"
            list={MinistriesList}
            edit={MinistriesEdit}
            create={MinistriesCreate}
            icon={FlareIcon}
          />,
          permissions && 
          <Resource
            name="reports"
            list={ReportsList}
            icon={DescriptionIcon}
          />,
          permissions && 
          <Resource
            name="campus_ministries"
            edit={CampusMinistriesEdit}
          />
      ]}
    </Admin>
  )
}

export default AppRoot
