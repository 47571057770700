import React, { forwardRef } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'

//eslint-disable-next-line react/display-name
const LogoutButton = forwardRef((props, ref) => {
  const handleClick = () => (window.location.href = '/logout')

  return (
    <MenuItem onClick={handleClick} ref={ref}>
      <ExitIcon /> Logout
    </MenuItem>
  )
})

export default LogoutButton
