import { createTheme } from '@material-ui/core/styles'

const ecaTheme = createTheme({
    palette: {
        primary: {
            main: '#666666'
        },
        secondary: {
           main: '#444444'
        },
        error: {
            main: '#e4594e'
        } 
    },
    overrides: {
        // Menu Styles
        MuiDrawer: { 
            root: { 
                backgroundColor: '#333',
                minHeight: '100vh',
                zIndex: 1
            }
        },
        RaMenuItemLink: {
            root: {
                color: 'white'
            },
            active: {
                color: 'white'
            }
        },
        MuiList: {
            root: {
                backgroundColor: '#f5f5f5'
            }
        },
        // Icons
        MuiListItemIcon: {
            root: {
                color: '#999'
            }
        },
        // Confirm Dialog
        MuiDialogContentText: {
            root: {
                minWidth: 400
            }
        },
        tonalOffset: 0.1,
    },
})

export default ecaTheme
