import React, { useState } from 'react'
import {
  DateField,
  Edit,
  ImageInput,
  ImageField,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import MinistriesDeleteWizard from '../MinistriesDeleteWizard/MinistriesDeleteWizard'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import { formatImage } from '../../utils/dataUtils.js'
const MinistriesEdit = props => {

  // state
  const [showDeleteWizard, setShowDeleteWizard] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState()

  const toggleDeleteWizard = () => {
    setShowDeleteWizard(!showDeleteWizard)
  }

  return (
    <>
      <Edit title='Edit Ministry' {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput source="name" />
          <TextField source="id" />
          <TextInput source="address1" />
          <TextInput source="address2" />
          <TextInput source="city" />
          <TextInput source="county" />
          <TextInput source="country" />
          <TextInput source="state" />
          <TextInput source="zipcode" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <TextInput source="url" />
          <ImageField source="icon_url" />
          <ImageInput format={ formatImage } source="icon_url" label="Icon" accept="image/*">
            <ImageField source="path" />
          </ImageInput>
          <ImageField source="brand_logo_url" />
          <ImageInput format={ formatImage } source="brand_logo_url" label="Logo" accept="image/*">
            <ImageField source="path" />
          </ImageInput>
        </SimpleForm>
      </Edit>
      <MinistriesDeleteWizard
        closeCallback={toggleDeleteWizard}
        deleteRecord={deleteRecord}
        isOpen={showDeleteWizard}
        stateCallback={setDeleteRecord}
      />
    </>
  )
}
export default MinistriesEdit
