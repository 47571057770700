import { isEqual, keys } from 'lodash'
import { saveAs } from 'file-saver'

export const downloadCsv = (csv, fileName) => {
  const file = new Blob([csv], { type: 'text/csv' })
  saveAs(file, fileName)
}

export const diffObject = (prevData, newData) =>
  keys(newData).reduce((diff, key) => {
    // need to specifically exclude campus_ministries bc react admin is adding it to newData even though it is not a field on campus
    if (!isEqual(newData[key], prevData[key]) && key !== 'campus_ministries') diff[key] = newData[key] === null || undefined ? '' : newData[key]
    return diff
  }, {} )

export const buildImageFormData = ( changedData ) => {
  const formData = new FormData()
  for ( const prop in changedData ) {
    if ( prop === 'icon_url' ) {
      const { icon_url } = changedData
      formData.append( 'icon_file', new Blob( [icon_url.rawFile] ) ,icon_url.rawFile.path )
    } else if ( prop === 'brand_logo_url' ) {
      const { brand_logo_url } = changedData
      formData.append( 'brand_logo_file', new Blob( [brand_logo_url.rawFile] ) ,brand_logo_url.rawFile.path )
    } else {
      formData.append( prop , changedData[prop] )
    }
  }
  return formData
}

export const formatImage = (value) => {
  if (!value ||  typeof value === 'string') {
    return { url: value }
   } else {
     return value
   }
}
