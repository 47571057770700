import React from 'react'
import {
    Create,
    ImageInput,
    ImageField,
    required,
    SimpleForm,
    TextInput
} from 'react-admin'
import { formatImage } from '../../utils/dataUtils.js'
//TODO: expand this in a future story

const MinistriesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" />
      <TextInput source="county" />
      <TextInput source="country" />
      <TextInput source="state" />
      <TextInput source="zipcode" />
      <TextInput source="url" />
      <ImageField source="icon_url" />
      <ImageInput format={ formatImage } source="icon_url" label="Icon" accept="image/*">
        <ImageField source="path" />
      </ImageInput>
      <ImageField source="brand_logo_url" />
      <ImageInput format={ formatImage } source="brand_logo_url" label="Logo" accept="image/*">
        <ImageField source="path" />
      </ImageInput>
    </SimpleForm>
  </Create>
)
export default MinistriesCreate
