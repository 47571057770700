import React from 'react'
import { array, string, object, oneOfType } from 'prop-types'
import Chip from '@material-ui/core/Chip'

const TextArrayField = ({ record, source }) => {
  const {[source]: data} = record

  if(Array.isArray(data)){
    return (
      <>
        {data.map(item => <Chip label={item} key={item}/>)}
      </>
    )
  }
  return null
}

TextArrayField.propTypes = {
  source: oneOfType([ string, array ]),
  record: object
}

export default TextArrayField
