import React from 'react'
import { Labeled } from 'react-admin'
import { useField } from 'react-final-form'
import { RadioGroup as MUIRadioGroup, Radio, FormControlLabel} from '@material-ui/core'
import {string, arrayOf, shape} from 'prop-types'


const RadioGroup = ({ name, title, radioButtons }) => {

  const {input} = useField(name)

  return (
    <Labeled label={title}>
      <MUIRadioGroup row value={input.value} onChange={input.onChange} {...input} >
        {radioButtons.map(({value, label}) => (
          <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
        ))}
      </MUIRadioGroup>
    </Labeled>
  )
}
RadioGroup.propTypes = {
  name: string.isRequired,
  radioButtons: arrayOf(shape({value: string, label: string})).isRequired,
  title: string.isRequired
}
export default RadioGroup
