import React from 'react'
import {
  SaveButton,
  Toolbar
} from 'react-admin'

const CustomToolbar = props => {

  return(
    <Toolbar { ...props } >
      <SaveButton />
    </Toolbar>
  )
}

export default CustomToolbar
