import React from 'react'
import { func, object } from 'prop-types'
import ActionDelete from '@material-ui/icons/Delete'
import { Button } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

// basePath, record, and resource props are provided by react admin to child components of data grid
const DeleteButton = ({
  clickCallback,
  record,
  stateCallback
}) => {
  // constants
  const classes = useStyles()

  // handlers
  const handleClick = e => {
    e.stopPropagation()
    clickCallback()
    stateCallback(record)
  }

  return (
    <Button
      className={classes.deleteButton}
      key="button"
      label="DELETE"
      onClick={handleClick}
    >
      <ActionDelete />
    </Button>
  )
}

const useStyles = makeStyles(
  theme => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'RaDeleteWithUndoButton' }
)

DeleteButton.propTypes = {
  clickCallback: func,
  record: object,
  stateCallback: func
}

export default DeleteButton
