import { get, isNil } from 'lodash'
import axios from 'axios'

export class ApiProxyError extends Error {
  constructor(statusCode, message, ...args) {
    super(message, ...args)

    this.name = 'ProxyError'
    this.statusCode = statusCode
    this.description = message

    // Remove ourselves from the stack trace if we're on the node side of
    // things
    Error.captureStackTrace && Error.captureStackTrace(this, ApiProxyError)
  }
}

export class ApiProxyClient {
  async request({ method, url, headers, data, params }) {
    const reqOptions = {
      method,
      url,
      headers,
      data,
      params
    }

    let res

    try {

      res = await axios(reqOptions)

    } catch (err) {
      console.log(err) // fix this later with an error handler

      throw new ApiProxyError(
        get(err, 'response.statusCode') || 500,
        get(err, 'response.message') || `Internal Error: ${err}`
      )

    }

    if (isNil(res)) {
      throw new ApiProxyError(500, 'Error, no response received')
    }

    return res
  }

  async GET(url, headers, params) {
    return await this.request({ method: 'GET', url, headers, params })
  }

  async POST(url, headers, data, params) {
    return await this.request({ method: 'POST', url, headers, data, params })
  }

  async UPLOAD(method, url, headers, formData) {
    return await this.request({ method, url, headers, data: formData })
  }

  async PUT(url, headers, data, params) {
    return await this.request({ method: 'PUT', url, headers, data, params })
  }

  async PATCH(url, headers, data, params) {
    return await this.request({ method: 'PATCH', url, headers, data, params })
  }

  async DELETE(url, headers, data, params) {
    return await this.request({ method: 'DELETE', url, headers, data, params })
  }
}

export default new ApiProxyClient()
