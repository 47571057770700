import React from 'react'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router-dom'

const CampusMinistryChips = ({ record }) => {

  const history = useHistory()

  const handleChipClick = (e, record) => {
    e.stopPropagation()
    history.push(`/campus_ministries/${record.id}`)
  }

  if (!record.campus_ministries) return false

  return (
    record.campus_ministries.map((campusMinistry) => (
      <Chip
        key={campusMinistry.id}
        label={ campusMinistry.ministry.name }
        onClick={ (e) => handleChipClick(e, campusMinistry) }
      />
    ))
  )
}

export default CampusMinistryChips
