import React, { useState, useEffect } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { bool, func, object } from 'prop-types'
import Confirm from '../Confirm/Confirm'
import ConfirmSelect from '../ConfirmSelect/ConfirmSelect'
import API from '../../utils/apiClient'

const MinistriesDeleteWizard = ({
  closeCallback,
  deleteRecord,
  isOpen,
  stateCallback
}) => {
  // state
  const [currentStep, setCurrentStep] = useState(1)
  const [ministries, setMinistries] = useState([])
  const [selectedMinistry, setSelectedMinistry] = useState()

  const notify = useNotify()
  const refresh = useRefresh()

  // utility functions
  const renderConfirmSelect = (handleSelection, options, selectedOption) => {
    return (
      <ConfirmSelect
        handleSelection={handleSelection}
        inputText="Select a ministry"
        options={options}
        selectedOption={selectedOption}
        text='Which ministry would you like to merge the data into?'
      />
    )
  }

  const setNextStep = action => {
    if (stepData[currentStep][action] === undefined) {
      handleClose()
    } else {
      setCurrentStep(stepData[currentStep][action])
    }
  }

  // constants
  const stepData = {
    1: {
      cancelStep: undefined,
      cancelText: 'No',
      confirmStep: 2,
      confirmText: 'Yes',
      content: 'Are you sure you want to delete this ministry?'
    },
    2: {
      cancelStep: 5,
      cancelText: 'No',
      confirmStep: 3,
      confirmText: 'Yes',
      content: 'Would you like to merge all of the ministry data to another ministry?'
    },
    3: {
      cancelStep: 2,
      cancelText: 'Back',
      confirmDisable: !selectedMinistry,
      confirmStep: 4,
      confirmText: 'Continue',
      content: () => renderConfirmSelect(handleMinistrySelect, ministries, selectedMinistry)
    },
    4: {
      cancelStep: 3,
      cancelText: 'No',
      confirmStep: undefined,
      confirmText: 'Yes',
      content: `Are you sure you would like to delete ${deleteRecord && deleteRecord.name} and merge its data into ${selectedMinistry && selectedMinistry.name}?`
    },
    5: {
      cancelStep: 2,
      cancelText: 'Back',
      confirmStep: 6,
      confirmText: 'Continue',
      content: 'All data for this ministry will be permanently lost.'
    },
    6: {
      cancelStep: 2,
      cancelText: 'Back',
      confirmStep: undefined,
      confirmText: 'Continue',
      content: 'There is no turning back now.'
    }
  }

  // effects
  useEffect(() => {
    const getMinistries = async () => {
      let response

      if (isOpen && ministries.length === 0) {
        try {
          response = await API.GET(
            '/api/v1/ministries',
            {},
            {
              fields: 'id,name',
              limit: 5000
            }
          )
        } catch (err) {
          console.error(err)
        }
      }

      if (response) setMinistries(response.data.content)
    }

    getMinistries()
  }, [isOpen])

  // handlers
  const handleCancel = () => {
    setNextStep('cancelStep')
  }

  const handleClose = () => {
    setCurrentStep(1)
    setSelectedMinistry()
    closeCallback()
    stateCallback()
  }

  const handleConfirm = async () => {
    setNextStep('confirmStep')
    let response
    if (currentStep === 4) {
      try {
        response = await API.POST(
          `/api/v1/ministries/${deleteRecord.id}/merge`,
          {},
          {
            target_ministry_id: selectedMinistry.id,
          }
        )
        refresh()
        notify(`${deleteRecord.name} Deleted`, 'info')
        return  {
          data: response
        }
      } catch (err) {
        console.error(err)
        notify(`${deleteRecord.name} Could Not Be Deleted`, 'warning')
      }
    }
    if (currentStep === 6) {
      try {
        response = await API.DELETE(`/api/v1/ministries/${deleteRecord.id}`)
        refresh()
        notify(`${deleteRecord.name} Deleted`, 'info')
        return  {
          data: response
        }
      } catch (err) {
        console.error(err)
        notify(`${deleteRecord.name} Could Not Be Deleted`, 'warning')
      }
    }

  }

  const handleMinistrySelect = (e, value) => {
    setSelectedMinistry(value)
  }

  return (
    <Confirm
      cancelText={stepData[currentStep].cancelText}
      confirmDisable={stepData[currentStep].confirmDisable}
      confirmText={stepData[currentStep].confirmText}
      handleCancel={handleCancel}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      isOpen={isOpen}
      content={stepData[currentStep].content}
    />
  )
}

MinistriesDeleteWizard.propTypes = {
  closeCallback: func,
  deleteRecord: object,
  isOpen: bool,
  stateCallback: func
}

export default MinistriesDeleteWizard
