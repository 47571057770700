import React, {useState, useEffect} from 'react'
import { DateField, DeleteButton, Edit, SaveButton, SimpleForm, TextField, TextInput, Toolbar, useDataProvider, Loading, Error} from 'react-admin'
import RadioGroup from '../RadioGroup/RadioGroup'

//Title can be used to grab dynamic content for the title prop. Should be expanded on in the future.

const CampusMinistriesEdit = props => {
const dataProvider = useDataProvider()


const [status, setStatus] = useState('established')
const [loading, setLoading] = useState(true)
const [error, setError] = useState()

const [visibility, setVisibility] = useState()

const handleStatusChange = (value) => {
  if(value) return setStatus('pending')
  return setStatus('established')
}

useEffect(() => {
  (async () => {
    dataProvider.getOne('campus_ministries',{id: props.id})
        .then(({ data: {visibility} }) => {
            setVisibility({visibility})
            setLoading(false)
        }).catch(error => {
            setError(error)
            setLoading(false)
        })
  })()
}, [])

  if (loading) return <Loading />
  if (error) return <Error />

 return (
  <Edit title='Edit Campus Ministry' {...props}>
    <SimpleForm
      redirect="/campuses"
      toolbar={
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <SaveButton/>
          <DeleteButton
            redirect="/campuses"
          />
        </Toolbar>
      }
    >
      <TextField source="id" />
      <TextField source="campus_id" />
      <TextField source="ministry_id" />
      <DateField source="created_at" />
      <DateField source="updated_at" />

      <RadioGroup
        type="radio"
        name='status'
        source='status'
        value={status}
        onChange={(e) => handleStatusChange(e.target.value)}
        title='Pending Status'
        radioButtons={[{value: 'pending', label: 'On'}, {value: 'established', label: 'Off'}]}
      />

      <RadioGroup
        type="radio"
        name='visibility'
        source='visibility'
        value={visibility}
        onChange={(e) => handleStatusChange(e.target.value)}
        title='Visibility'
        radioButtons={[{value: 'public', label: 'Public'}, {value: 'private', label: 'Private'}]}
      />
      <TextInput source="contact_name" />
      <TextInput source="contact_email" />
    </SimpleForm>
  </Edit>
)}

export default CampusMinistriesEdit
