import React from 'react'
import { object, string } from 'prop-types'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'

// styles
const imageStyle = {
  margin: '0.5rem',
  maxHeight: '10rem',
}

const ImageField = ({ record, source, title }) => {
  const sourceValue = get(record, source)

  const ImageElement = () => (
    <img
      title={title}
      alt={title}
      src={sourceValue}
      style={imageStyle}
    />
  )

  return (
    sourceValue && (
      <>
        {typeof sourceValue === 'string' ? (
          <ImageElement />
        ) : (
          <CircularProgress />
        )}
      </>
    )
  )
}

ImageField.propTypes = {
  record: object,
  source: string,
  title: string,
}

export default ImageField
