import React, { useState } from 'react'
import { useLogin } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import './Login.scss'

const Login = () => {
  // constants
  const login = useLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')

  // handlers
  const handleSubmit = e => {
    e.preventDefault()
    setLoginError('')
    login({ email, password }).catch((err) => {
      setLoginError(err.response.statusText)
    })
  }

  return (
    <div className='loginContainer'>
      <div className='loginHeaderContainer'>
        <h3 className='loginHeaderText'>EveryCampus Data Admin Tool</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Email'
          name='email'
          type='email'
          value={email}
          required={true}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          label='Password'
          name='password'
          type='password'
          value={password}
          required={true}
          onChange={e => setPassword(e.target.value)}
        />
        <button type='submit' className='loginButton'>Login</button>
        { loginError && (
          <label className='error'>{loginError}</label>
        )}
      </form>
    </div>
  )
}

export default Login
