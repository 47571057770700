import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useDataProvider
} from 'react-admin'
import WalkerStats from '../WalkerStats/WalkerStats'
import DownloadButton from '../DownloadButton/DownloadButton'

const ReportsList = props => {

// constants

const dataProvider = useDataProvider()

//handlers

const downloadReport = (id) => {
  dataProvider.downloadFile('reports',id)
}
  return (
    <>
      <WalkerStats />
      <List {...props} exporter={false} sort={{ field: 'created_at', order: 'ASC' }} actions={null} bulkActionButtons={false}>
        <Datagrid>
          <TextField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <DateField source="created_at" sortable={false} />
          <DownloadButton clickCallback={downloadReport} />
        </Datagrid>
      </List>
    </>
  )
}

export default ReportsList
