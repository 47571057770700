import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import CampusMinistryChips from '../CampusMinistryChips/CampusMinistryChips'

const CampusesCreate = props => (
  <Create {...props}>
    <SimpleForm>
    <TextInput source="name" validate={required()}/>
    <TextInput source="sortable_name" />
    <TextInput source="stylized_name" />
    <TextInput source="shortname" />
    <ArrayInput source="aliases">
      <SimpleFormIterator>
        <TextInput />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput source="abbrv" />
    <TextInput source="unit_id" />
    <CampusMinistryChips source="campus_ministries" />
    <TextInput source="address1" validate={required()} />
    <TextInput source="address2" />
    <TextInput source="city" validate={required()} />
    <TextInput source="county" />
    <TextInput source="state" validate={required()} />
    <TextInput source="country" />
    <TextInput source="zipcode" validate={required()} />
    <NumberInput source="zip_plus4" />
    <NumberInput source="address_type" />
    <TextInput source="phone" />
    <TextInput source="fax" />
    <TextInput source="url" />
    <NumberInput source="latitude" validate={required()} />
    <NumberInput source="longitude" validate={required()} />
    <TextInput source="ipeds_id" validate={required()} />
    <DateInput source="established_at" />
    <NumberInput source="enrollment" />
    <NumberInput source="doe_denomination_code" />
    <BooleanInput source="gfm_only" />
    <BooleanInput source="ncf_only" />
    <BooleanInput source="nursing_offered" />
    <BooleanInput source="theology_offered" />
    <BooleanInput source="is_satellite" />
    <BooleanInput source="is_medical_nursing_school" />
    <BooleanInput source="is_religious_school" />
    <BooleanInput source="is_christian_seminary" />
    <TextInput source="scope" />
    </SimpleForm>
  </Create>
)

export default CampusesCreate
