import React, { useState } from 'react'
import { 
  Datagrid, 
  DateField, 
  List, 
  TextField, 
  UrlField 
} from 'react-admin'
import ImageField from '../ImageField/ImageField'
import MinistriesFilter from './MinistriesFilter.js'
import MinistriesDeleteWizard from '../MinistriesDeleteWizard/MinistriesDeleteWizard'
import DeleteButton from '../DeleteButton/DeleteButton'

const MinistriesList = props => {
  // state
  const [showDeleteWizard, setShowDeleteWizard] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState()

  // utility functions
  const toggleDeleteWizard = () => {
    setShowDeleteWizard(!showDeleteWizard)
  }

  return (
    <>
      <List {...props} filters={<MinistriesFilter />} exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick='edit'>
          <TextField source='name' sortable={false} />
          <TextField source='id' sortable={false} />
          <TextField source='address1' sortable={false} />
          <TextField source='city' sortable={false} />
          <TextField source='state' sortable={false} />
          <TextField source='county' sortable={false} />
          <TextField source='country' sortable={false} />
          <TextField source='zipcode' sortable={false} />
          <DateField source='created_at' sortable={false} />
          <DateField source='updated_at' sortable={false} />
          <UrlField source='url' sortable={false} />
          <ImageField source='icon_url' sortable={false} />
          <ImageField source='brand_logo_url' sortable={false} />
          <DeleteButton clickCallback={toggleDeleteWizard} stateCallback={setDeleteRecord} />
        </Datagrid>
      </List>
      <MinistriesDeleteWizard
        closeCallback={toggleDeleteWizard}
        deleteRecord={deleteRecord}
        isOpen={showDeleteWizard}
        stateCallback={setDeleteRecord}
      />
    </>
  )
}

export default MinistriesList
