import React from 'react'
import { Button } from 'react-admin'
import GetAppIcon from '@material-ui/icons/GetApp'
import { func, object, string } from 'prop-types'

const DownloadButton = ({ clickCallback, record }) => {

  const downloadReport = () => {
    clickCallback(record.id)
  }

  return (
    <Button 
      label="Download"
      key="button"
      onClick={downloadReport}>
      <GetAppIcon />
    </Button>
  )

}

DownloadButton.propTypes = {
  clickCallback: func,
  record: object
}

export default DownloadButton
