import React from 'react'
import { render } from 'react-dom'
import AppRoot from './App/App'

window.__APP_ROOT__ = document.getElementById('app')

if (module.hot) {
  module.hot.accept()
}

render(
  <AppRoot appName="EveryCampus Admin" />,
  window.__APP_ROOT__
)
