import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { ListSubheader } from '@material-ui/core'


const WalkerStats = () => {
  const dataProvider = useDataProvider()
  const [ stats, setStats ] = useState()

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await dataProvider.getSimple('reports/walker_stats')
        const getData = ({data}) => data[0]
        setStats(getData(response))
      } catch(error) {
        console.error(error)
      }
    }
    getStats()
  }, [])

  const renderStats = (stats) => {
    const uniqueCount = stats.unique_count.toString()
    const optedInCount = stats.opted_in_count.toString()
    return (
        <>
          <b>Unique Walkers :</b> {`${ uniqueCount }`} | <b>Opted In Walkers :</b> {`${ optedInCount }`}
        </>
    )
  }

  return (
    <ListSubheader>
      {
       stats && renderStats(stats) 
      }
    </ListSubheader>
  )
}

export default WalkerStats