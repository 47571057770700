import axios from 'axios'

export const getLoggedIn = () => {
  return (
    document.cookie.split(';').filter(item => item.includes('loggedIn=true'))
      .length > 0
  )
}

const authProvider = {
  login: (credentials) => {
    return axios.post('/login', {
      username: credentials.email,
      password: credentials.password
    })
  },
  logout: () => {
    return Promise.resolve()
  },
  checkAuth: () => {
    return getLoggedIn() ? Promise.resolve() : Promise.reject()
  },
  checkError: err => {
    if (err) {
      return Promise.resolve()
    } else {
      return Promise.resolve()
    }
  },
  getPermissions: () => {
   const loggedIn =  getLoggedIn()
   return loggedIn ? Promise.resolve(true) : Promise.reject()
  }
}

export default authProvider
