import React, { useState } from 'react'
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  DateField,
  DateInput,
  Edit,
  NumberInput,
  NumberField,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  SelectInput,
} from 'react-admin'
import CampusMinistryChips from '../CampusMinistryChips/CampusMinistryChips'
import CampusesDeleteWizard from '../CampusesDeleteWizard/CampusesDeleteWizard'
import CustomToolbar from '../CustomToolbar/CustomToolbar'


const CampusesEdit = props => {

  // state
  const [showDeleteWizard, setShowDeleteWizard] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState()

  // utility functions
  const toggleDeleteWizard = () => {
    setShowDeleteWizard(!showDeleteWizard)
  }

  return (
    <>
      <Edit title='Edit Campus' {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput source="name" />
          <TextInput source="sortable_name" />
          <TextInput source="stylized_name" />
          <TextInput source="shortname" />
          <ArrayInput source="aliases">
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="abbrv" />
          <TextField source="id" />
          <TextInput source="unit_id" />
          <CampusMinistryChips source="campus_ministries" />
          <TextInput source="address1" />
          <TextInput source="address2" />
          <TextInput source="city" />
          <TextInput source="county" />
          <TextInput source="state" />
          <TextField source="country" />
          <TextInput source="zipcode" />
          <TextInput source="zip_plus4" />
          <BooleanField source="unique_zip" />
          <NumberInput source="address_type" />
          <TextInput source="phone" />
          <TextInput source="fax" />
          <TextInput source="url" />
          <TextField source="institution_size_text" />
          <NumberField source="institution_size_code" />
          <NumberInput source="latitude" />
          <NumberInput source="longitude" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <TextInput source="ipeds_id" />
          <TextField source="everycampus_id" />
          <DateInput source="established_at" />
          <NumberInput source="enrollment" />
          <TextField source="locale_type" />
          <TextField source="undergrad_offered" />
          <TextField source="graduate_offered" />
          <TextField source="highest_degree_offered" />
          <NumberField source="carnegie_classification" />
          <NumberField source="doe_affiliation_code" />
          <NumberInput source="doe_denomination_code" />
          <TextField source="institution_type" />
          <TextField source="ope_id" />
          <NumberField source="delivery_point_code" />
          <NumberField source="fice_number" />
          <TextField source="ic_level" />
          <SelectInput
            label="Two year or four year"
            source="two_year_four_year"
            optionText="name"
            optionValue="id"
             choices={[
                { id: 'unspecified', name: 'Unspecified' },
                { id: 'two_year', name: 'Two Year' },
                { id: 'four_year', name: 'Four Year' },
              ]
            }/>
          <SelectInput
            label="Public or Private Institution"
            source="public_or_private"
            optionText="name"
            optionValue="id"
            choices={[
              { id: 'unspecified', name: 'Unspecified' },
              { id: 'private', name: 'Private University'},
              { id: 'public', name: 'Public University'}
            ]}
          />
          <BooleanInput source="gfm_only" />
          <BooleanInput source="ncf_only" />
          <BooleanInput source="nursing_offered" />
          <BooleanInput source="theology_offered" />
          <BooleanInput source="is_satellite" />
          <BooleanInput source="is_medical_nursing_school" />
          <BooleanInput source="is_religious_school" />
          <BooleanInput source="is_christian_seminary" />
          <BooleanInput source="is_hispanic_serving" />
          <BooleanInput source="is_hbcu" />
          <BooleanInput source="is_tribal_college" />
          <BooleanInput source="is_art_school" />
          <BooleanInput source="is_professional_school" />
          <BooleanInput source="is_cccu_school" />
          <BooleanInput source="is_trade_school" />
          <TextInput source="scope" />
        </SimpleForm>
      </Edit>
      <CampusesDeleteWizard
        closeCallback={toggleDeleteWizard}
        deleteRecord={deleteRecord}
        isOpen={showDeleteWizard}
        stateCallback={setDeleteRecord}
      />
    </>
  )
}

export default CampusesEdit
