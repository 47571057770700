import React, { useState } from 'react'
import {
  BooleanField,
  ChipField,
  DateField,
  List,
  Datagrid,
  NumberField,
  SingleFieldList,
  TextField,
  UrlField
} from 'react-admin'
import CampusMinistryChips from '../CampusMinistryChips/CampusMinistryChips'
import CampusesFilter from './CampusesFilter'
import TextArrayField from '../TextArrayField/TextArrayField'
import CampusesDeleteWizard from '../CampusesDeleteWizard/CampusesDeleteWizard'
import DeleteButton from '../DeleteButton/DeleteButton'

const CampusesList = props => {

  // state
  const [showDeleteWizard, setShowDeleteWizard] = useState(false)
  const [deleteRecord, setDeleteRecord] = useState()

  // utility functions
  const toggleDeleteWizard = () => {
    setShowDeleteWizard(!showDeleteWizard)
  }

  return (
  <>
    <List {...props} filters={<CampusesFilter />} bulkActionButtons={false} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="name"  sortable={false} />
        <TextField source="sortable_name"  sortable={false} />
        <TextField source="stylized_name"  sortable={false} />
        <TextField source="shortname"  sortable={false} />
        <TextArrayField source="aliases" sortable={false}>
          <SingleFieldList>
            <ChipField source="id" />
          </SingleFieldList>
        </TextArrayField>
        <TextField source="abbrv" sortable={false} />
        <TextField source="id" sortable={false} />
        <TextField source="unit_id" sortable={false} />
        <CampusMinistryChips source="campus_ministries" sortable={false} />
        <TextField source="address1" sortable={false} />
        <TextField source="address2" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="county" sortable={false} />
        <TextField source="state" sortable={false} />
        <TextField source="country" sortable={false} />
        <NumberField source="zipcode" sortable={false} />
        <TextField source="zip_plus4" sortable={false} />
        <BooleanField source="unique_zip" sortable={false} />
        <NumberField source="address_type" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="fax" sortable={false} />
        <UrlField source="url" sortable={false} />
        <TextField source="institution_size_text" sortable={false} />
        <NumberField source="institution_size_code" sortable={false} />
        <NumberField source="latitude" sortable={false} />
        <NumberField source="longitude" sortable={false} />
        <DateField source="created_at" sortable={false} />
        <DateField source="updated_at" sortable={false} />
        <TextField source="ipeds_id" sortable={false} />
        <TextField source="everycampus_id" sortable={false} />
        <DateField source="established_at" sortable={false} />
        <NumberField source="enrollment" sortable={false} />
        <TextField source="locale_type" sortable={false} />
        <TextField source="undergrad_offered" sortable={false} />
        <TextField source="graduate_offered" sortable={false} />
        <TextField source="highest_degree_offered" sortable={false} />
        <NumberField source="carnegie_classification" sortable={false} />
        <NumberField source="doe_affiliation_code" sortable={false} />
        <NumberField source="doe_denomination_code" sortable={false} />
        <TextField source="institution_type" sortable={false} />
        <TextField source="ope_id" sortable={false} />
        <NumberField source="delivery_point_code" sortable={false} />
        <NumberField source="fice_number" sortable={false} />
        <TextField source="ic_level" sortable={false} />
        <BooleanField source="gfm_only" sortable={false} />
        <BooleanField source="ncf_only" sortable={false} />
        <BooleanField source="nursing_offered" sortable={false} />
        <BooleanField source="theology_offered" sortable={false} />
        <BooleanField source="is_satellite" sortable={false} />
        <BooleanField source="is_medical_nursing_school" sortable={false} />
        <BooleanField source="is_religious_school" sortable={false} />
        <BooleanField source="is_christian_seminary" sortable={false} />
        <TextField source="scope" sortable={false} />
        <DeleteButton
          clickCallback={toggleDeleteWizard}
          stateCallback={setDeleteRecord}
        />
      </Datagrid>
    </List>
    <CampusesDeleteWizard
      closeCallback={toggleDeleteWizard}
      deleteRecord={deleteRecord}
      isOpen={showDeleteWizard}
      stateCallback={setDeleteRecord}
    />
  </>
)
}

export default CampusesList
